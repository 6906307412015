@import url("https://fonts.googleapis.com/css?family=Raleway:400,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
@import url("https://fonts.googleapis.com/css?family=Headland+One");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700");
html *,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
::selection {
  color: #222;
  background: #f9bf3b;
}
html {
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 100%;
  height: 100%;
}
body {
  font: 1rem/2 'Headland One', sans-serif;
  font-style: normal;
  height: 100%;
  background: #fff;
  color: #333;
}
ul,
ol {
  list-style: none;
}
a {
  text-decoration: none;
}
header,
main,
footer {
  display: block;
}
