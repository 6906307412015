@import url("https://fonts.googleapis.com/css?family=Raleway:400,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
@import url("https://fonts.googleapis.com/css?family=Headland+One");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700");
header {
  background: url("../img/wall.jpg") no-repeat #d6813b;
  background-size: cover;
  padding: 2rem;
}
@media only screen and (min-width: 37.5em) {
  header {
    padding: 4rem;
  }
}
@media only screen and (min-width: 64em) {
  header {
    padding: 6rem;
  }
}
@media only screen and (min-width: 90em) {
  header {
    width: 50%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 8;
    padding: 8rem;
  }
}
header h1 a {
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  width: 245.25px;
  height: 116.25px;
  margin: 0 auto 2rem;
  background: url("../img/mjk-logo.svg") no-repeat;
  background-size: 100%;
}
@media only screen and (min-width: 37.5em) {
  header h1 a {
    width: 327px;
    height: 155px;
  }
}
header ul {
  color: #fff;
  text-align: center;
  color: #5a3313;
  padding-top: 2rem;
}
header ul:before {
  content: '';
  display: block;
  position: relative;
  top: -2rem;
  width: 50%;
  margin: 0 auto;
  border-top: 1px solid #9e5a21;
}
@media only screen and (min-width: 90em) {
  header ul {
    font-size: 1.2rem;
  }
}
main {
  padding: 2rem;
}
@media only screen and (min-width: 37.5em) {
  main {
    padding: 4rem;
  }
}
@media only screen and (min-width: 64em) {
  main {
    padding: 6rem;
  }
}
@media only screen and (min-width: 90em) {
  main {
    float: right;
    width: 50%;
    padding: 8rem;
  }
}
main h2 {
  font-family: 'Raleway';
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1;
  padding-bottom: 2rem;
  word-wrap: break-word;
}
@media only screen and (min-width: 90em) {
  main h2 {
    font-size: 3rem;
  }
}
main h2:nth-of-type(2) {
  font-size: 2rem;
  margin-top: 2rem;
}
@media only screen and (min-width: 90em) {
  main h2:nth-of-type(2) {
    margin-top: 4rem;
  }
}
main p {
  margin-bottom: 2rem;
}
main a {
  color: #b56626;
  text-decoration: none;
  border-bottom: 1px solid;
  transition: color 0.1s ease;
}
main a:focus,
main a:hover,
main a:active {
  color: #5a3313;
}
main img {
  display: block;
  max-width: 100%;
  margin-bottom: 2rem;
}
main img.align_right {
  float: right;
  margin-left: 2rem;
}
main img.align_left {
  float: left;
  margin-right: 2rem;
}
main img.align_center {
  margin: 0 auto 2rem;
}
main .inner {
  opacity: 0;
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
  transition: transform 0.2s ease, opacity 0.2s ease;
}
main .inner.visible {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.inner {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}
@media only screen and (min-width: 37.5em) {
  #project-links,
  #project-photos {
    margin-top: 2rem;
  }
}
#project-links a,
#project-photos a {
  border: 0;
}
#project-links .grid-item,
#project-photos .grid-item {
  width: 46%;
  margin-bottom: 20px;
  background: #222;
}
@media only screen and (min-width: 30em) {
  #project-links .grid-item,
  #project-photos .grid-item {
    width: 47%;
  }
}
@media only screen and (min-width: 60em) {
  #project-links .grid-item,
  #project-photos .grid-item {
    width: 31%;
  }
}
@media only screen and (min-width: 90em) {
  #project-links .grid-item,
  #project-photos .grid-item {
    width: 47%;
  }
}
@media only screen and (min-width: 100em) {
  #project-links .grid-item,
  #project-photos .grid-item {
    width: 31%;
  }
}
#project-links .grid-item:hover img,
#project-photos .grid-item:hover img {
  -webkit-transform: translate(10px, -10px);
  -ms-transform: translate(10px, -10px);
  transform: translate(10px, -10px);
}
#project-links .grid-item img,
#project-photos .grid-item img {
  display: block;
  max-width: 100%;
  transition: transform 0.2s ease;
  border: 0;
  margin: 0;
}
#close-link {
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 50px;
  right: -1rem;
  top: -1rem;
  border: 0;
  transition: transform 0.2s ease;
  z-index: 2;
}
#close-link:focus,
#close-link:hover,
#close-link:active {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
@media only screen and (min-width: 37.5em) {
  #close-link {
    right: -2rem;
    top: -2rem;
  }
}
#baguetteBox-overlay {
  background-color: #43374d;
  background-color: rgba(67,55,77,0.8);
}
#baguetteBox-overlay .baguetteBox-button {
  background: none;
}
#baguetteBox-overlay #close-button {
  position: absolute;
  left: 2rem;
  top: 2rem;
  transition: transform 0.2s ease;
}
#baguetteBox-overlay #close-button:focus,
#baguetteBox-overlay #close-button:hover,
#baguetteBox-overlay #close-button:active {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
